@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~animate.css';



[v-cloak] { display:none; }
[wire\:loading], [wire\:loading\.delay] {
  display: none;
}
[x-cloak] { display: none; }

[wire\:offline] {
  display: none;
}

[wire\:dirty]:not(textarea):not(input):not(select) {
  display: none;
}

html {
  scroll-behavior: smooth !important;
}
.syne {
  font-family: 'Poiret One','sans-serif' !important;
  text-transform:uppercase;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none !important;
}

// Apply on overflow-hidden element containing image
.mask-corners {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.snap {
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -webkit-scroll-snap-type: x;
  -webkit-overflow-scrolling: touch;
}


.search-radius {
  border-bottom-left-radius: 8px;
}

.snap::-webkit-scrollbar {
  display: none;
}

.snap > div {
  scroll-snap-align: center;
}

.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}

.animate-height {
  transition: height 1s;
}

.animate-corner {
  transition: border-radius 1s;
}

.bg {
  &-blur {
    &-light {
      background-color: white;
      background-color: rgba(255, 255, 255, 0.9);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }

    &-lighter {
      background-color: white;
      background-color: rgba(255,255,255,0.6);
      -webkit-backdrop-filter: saturate(180%) blur(40px);
      backdrop-filter: saturate(180%) blur(40px);
    }
    &-lighter-transparent {
      background-color: white;
      background-color: rgba(255,255,255,0.2);
      -webkit-backdrop-filter: saturate(80%) blur(10px);
      backdrop-filter: saturate(80%) blur(10px);
    }
    &-dark {
      background-color: black;
      background-color: rgba(0,0,0,0.9);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
    opacity: 0.94 !important;
    -webkit-backdrop-filter: saturate(180%) blur(40px) !important;
    backdrop-filter: saturate(180%) blur(40px) !important;

  }
}
.blur-plus {
  -webkit-backdrop-filter: saturate(180%) blur(40px) !important;
  backdrop-filter: saturate(180%) blur(40px) !important;
}

.animate-height {
  transition: height 2s;
}
